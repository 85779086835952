.main__container {
  height: 100%;
  width: 100%;
  text-align: left;
}

/* ------- Fonts ----------- */

.montserrat__bold {
  font-family: "Montserrat";
  font-weight: 700;
}

/* ------- Container - 1 ----------- */

.container__1 {
  height: auto;
  width: 100%;
  padding: 6%;
  padding-top: 30px;
  background-color: #e3f5ff;
}
.container__1__title {
  margin-bottom: 2%;
}

/* ------- Container - 2 ----------- */

.container__2 {
  height: auto;
  width: 100%;
  padding: 6%;
  background-color: rgba(207, 176, 100, 0.3294);
}
.container__2__title {
  margin-bottom: 4%;
  text-align: center;
}

/* ------- Container - 3 ----------- */

.container__3 {
  height: auto;
  width: 100%;
  padding: 6%;
  background-color: rgba(211, 130, 193, 0.45);
}
.container__3__title {
  margin-bottom: 4%;
  text-align: center;
}

/* ------- Container - 3 ----------- */

.container__4 {
  height: auto;
  width: 100%;
  padding: 6%;
  background-color: rgba(107, 218, 178, 0.5);
}
.container__4__title {
  margin-bottom: 4%;
  text-align: center;
}

.font__50 {
  font-size: 3.5rem;
}

.title__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom__back__button {
  border-radius: 50%;
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.5);
  /* width: 5vw;
  height: 9vh; */
  width: 92px;
  height: 92px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 30px;
  padding-bottom: 10px;
  padding-right: 5px;
  font-size: 2.7rem;
  cursor: pointer;
  background: transparent;
}
.custom__back__button img {
  transform: translateY(6px);
  object-fit: contain;
  max-width : 150%;
}

/* ----------------------------------Responsive---------------------------- */

@media (max-width : 1700px ) {
  .custom__back__button {
    width : 85px;
    height : 85px;
  }
}

@media (max-width: 1500px) {
  .custom__back__button {
    width: 80px;
    height: 80px;
  }
  .custom__back__button img {
    width: 100%;
    height: 100%;
    transform: translate(1px, 6px) scale(1.6);
    object-fit: contain;
  }
}

@media (max-width : 1400px) {
  .custom__back__button {
      width: 65px;
      height: 65px;
    }
}