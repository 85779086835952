@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    20% {
        opacity: 1;
        transform: translateY(0);
    }

    80% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

.notifyContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    position: fixed;
    top: 90px;
    opacity : 0;
    z-index: 1000;
    animation: fadeInOut 5s ease-in-out;
}

.notify {
    width: 520px;
    height: 120px;
    border-radius: 100px;
    background-color: black;
    box-shadow: 0 0 18px 9px #0C4D62, inset 0 0 20px 8px rgba(29, 183, 232, 0.5);
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
}

.notify p {
    font-family: "Montserrat";
    margin: 0;
    padding: 0;
}




/* -------------- media queries -------------- */

@media (max-width : 1450px) {
    .notify {
        width: 430px;
        height: 100px;
        border-radius: 100px;
        box-shadow: 0 0 15px 8px #0C4D62, inset 0 0 18px 7px rgba(29, 183, 232, 0.5);
        font-size: 16px;
        padding: 20px;
    }
}

@media (max-width : 1200px) {
    .notify {
        width: 380px;
        height: 90px;
        border-radius: 100px;
        box-shadow: 0 0 13px 7px #0C4D62, inset 0 0 15px 6px rgba(29, 183, 232, 0.5);
        font-size: 15px;
        padding: 15px;
    }
}