.Landscapeview {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  margin-bottom: 20vh;
}

.Topbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 20%;
  font-family: "Fjalla One";
  font-size: 5vw;
  color: black;
  padding-left: 3vw;
}

.Bottombox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
  flex-direction: row;
}

.Leftbox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.Rightbox {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3vw;
  width: 100%;
  height: 100%;
}

.subcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #bebebe;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.45);

}

/* ----------Kiosk and its animations--------------- */

@keyframes scaleUpDown {
  0% {
    transform: scale(0.9); /* Initial scale */
  }
  50% {
    transform: scale(1.1); /* Scale up by 10% */
  }
  100% {
    transform: scale(0.9); /* Scale back to normal */
  }
}

.kiosk {
  width: 20vw;
  animation: scaleUpDown 3s ease-in-out infinite;
}

.kiosk img {
  max-width: 100%;
}

.Topbox1,
.Bottombox1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 50%;
  padding: 20px;
  gap: 50px;
}

.Box1,
.Box2,
.Box3,
.Box4 {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25vh;
  background-color: white;
  border-radius: 50px;
  flex-direction: column;
}

.Topheader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  font-family: "Montserrat";
  font-size: 1.2vw;
  font-weight: 800;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.Topheadera {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  font-family: "Montserrat";
  font-size: 1.2vw;
  font-weight: 800;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.Bottomheader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  font-family: "Montserrat";
  font-size: 1vw;
  font-weight: 300;
}

.Potraitview {
  display: none;
}

@media (max-width: 1800px) {
  .subcontainer {
    height: 90%;
  }
}

@media (max-width: 1600px) {
  .subcontainer {
    height: 80%;
  }
  .Box1,
  .Box2,
  .Box3,
  .Box4 {
    height: 22vh;
    padding: 2px;
    border-radius: 30px;
  }
  .Topheader {
    font-size: 1.3vw;
  }
  .Topheadera {
    font-size: 1vw;
  }
  .Bottomheader {
    font-size: 1vw;
  }
}

@media (max-width: 1400px) {
  .Box1,
  .Box2,
  .Box3,
  .Box4 {
    height: 18vh;
    border-radius: 30px;
    padding: 2px;
  }
}

@media (max-width: 1200px) {
  .subcontainer {
    height: 70%;
  }
  .Box1,
  .Box2,
  .Box3,
  .Box4 {
    height: 15vh;
    border-radius: 15px;
  }
}

@media (max-width: 1150px) {
  .subcontainer {
    height: 65%;
    border-radius: 30px;
  }
}

@media (max-width: 1100px) {
  .subcontainer {
    height: 55%;
  }
}

@media (max-width: 1000px) {
  .Box1,
  .Box2,
  .Box3,
  .Box4 {
    height: 13vh;
  }
  .subcontainer {
    height: 50%;
  }
}

/* --------Medium Devices------------ */

@media (max-width: 992px) {
  .Landscapeview {
    display: none;
  }
  .Potraitview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    user-select: none;
  }
  .kiosk {
    width: 30vw;
  }
  .Topbox {
    text-align: left;
    padding-top: 2vh;
  }
  .Bottombox {
    flex-direction: column;
    height: 100%;
  }
  .Bottomboxm {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 30px;
  }
  .subcontainer {
    height: 75vh;
    width: 80vw;
    border-radius: 80px;
    justify-content: center;
    align-items: center;
    gap: 5vh;
    padding: 50px;
    box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.45);
  }
  .Content1,
  .Content2,
  .Content3,
  .Content4 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    width: 70vw;
    border-radius: 30px;
    background-color: white;
    flex-direction: column;
  }
  .Topheader {
    font-size: 3vw;
  }
  .Bottomheader {
    font-size: 2vw;
  }
  .Topheadera {
    font-size: 3vw;
  }
}

/* --------Small Devices------------ */

@media (max-width: 576px) {
  .Leftbox {
    padding-top: 5vw;
    padding-bottom: 5vw;
  }
  .kiosk {
    width: 35vw;
  }
  .subcontainer {
    border-radius: 45px;
  }
}

/* --------Small Devices------------ */

@media (max-width: 430px) {
  .Topbox {
    font-size: 8vw;
  }
  .subcontainer {
    height: 75vh;
  }
  .Content1,
  .Content2,
  .Content3,
  .Content4 {
    height: 50vh;
    width: 70vw;
  }
  .Bottomheader {
    font-size: 2.5vw;
  }
  .Topheader {
    text-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
  }
}

/* --------Small Devices------------ */

@media (max-width: 416px) {
  .subcontainer {
    height: 60vh;
    
  }

  .Topheader {
    font-size: 2.8vw;}
  .Topheadera {
    font-size: 2.8vw;
    text-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);

  }
}