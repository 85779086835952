.bg {
  background-image: url("/public/ContactForm-Bg/ContactFormBg.png");
  background-repeat: no-repeat;
  background-position: center top;
  display: flex;
  position: absolute;
  width: 100vw;
  height: auto;
  min-height: 100vh;
}
.loaderContainer {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}
.form_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.FormBox {
  display: flex;
  width: 500px;
  margin: auto;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 0 10px rgb(249, 244, 244);
  background-color: #6b9cba9e;
  /* box-shadow: inset 0 0 60px rgba(251, 249, 249, 0.363);
  background-color: #4c96cb38; */
}

.Form {
  max-width: 100%;
}
.row {
  margin: 20px 0;
}

.name {
  display: flex;
  margin-bottom: 10px;
}

.formcontrol {
  width: 100%;
  height: 80px;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 3px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  vertical-align: top;
  line-height: 100px;
  border: none;
}
.formcontrol::placeholder {
  vertical-align: top;
}

.form-check {
  margin-top: 12px;
  margin-bottom: 12px;
}

.formcontrol1 {
  width: 100%;
  height: 40px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  border-width: 0;
}

.threetier {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.threetier > div {
  width: 31%;
}

.button {
  border-radius: 8px;
  background-color: #166799;
  height: 34px;
  width: 90px;
  margin-top: 10px;
  color: rgb(238, 238, 238);
  cursor: pointer;
  /* border-color: rgb(222, 210, 210); */
  border: 0;
  border-width: 1px;
  box-shadow: 0px 0px 3px rgba(251, 249, 249, 0.15),
    inset 2px 2px 5px rgba(255, 255, 255, 0.2),
    0 0 20px rgba(252, 252, 252, 0.48);
}

.button2 {
  display: inline-block;
  border-radius: 8px;
  background-color: #166799;
  height: 34px;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  width: 80px;
  text-align: center;
  border: 0;
  border-width: 1px;
  box-shadow: 0px 0px 3px rgba(251, 249, 249, 0.15),
    inset 2px 2px 5px rgba(255, 255, 255, 0.2),
    0 0 20px rgba(252, 252, 252, 0.48);
}
.inputbox {
  border: 0;
  height: 40px;
  background-color: rgb(0, 0, 0);
  color: rgb(253, 250, 250);
}

.name,
.formlabel,
.formlabel2,
.formlabel3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-shadow: 0.5px 0px 0px rgba(0, 0, 0, 0.8);
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,600&display=swap");

.formcontrol3 {
  width: 100%;
  height: 80px;
  padding: 8px;
  margin-top: 10px;
  margin-bottom: 12px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  vertical-align: top;
  line-height: 100px;
  border: none;
}

.formlabel {
  display: flex;
}

.formlabel2 {
  display: flex;
}

.formlabel3 {
  display: flex;
  margin-bottom: 10px;
}

.threetier label {
  margin-bottom: 10px;
}

.button__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Potraitview {
  display: none;
}

.Landscapeview {
  display: flex;
}

/* Breakpoints */

/* Background Breakpoint */
@media (max-width: 1000px) {
  .bg {
    background-color: rgb(255, 255, 255);
    background-image: none;
  }
}

@media (max-width: 1543px) {
  .bg {
    background-size: 104.5% 100%;
  }
  .FormBox {
    transform: scale(0.9);
  }
}

@media (max-width: 1377px) {
  .bg {
    /* background-size: contain; */
    background-size: 100% 100%;
  }
  .FormBox {
    transform: scale(0.8);
  }
}
@media (max-width: 1230px) {
  .FormBox {
    transform: scale(0.7);
  }
}

@media (min-width: 1544px) {
  .bg {
    background-size: 100% 108%;
  }
}

/* ----------Large Devices------------ */
@media (max-width: 992px) {
  .Potraitview {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex-direction: row;
    position: relative;
    overflow: hidden;
  }

  .Landscapeview {
    display: none;
  }

  .form_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .firstbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    position: relative;
    z-index: 1;
  }
  .secondbox {
    display: flex;
    justify-content: center;
    width: 60%;
    position: relative;
    z-index: 1;
    transform: scale(1.25);
    padding-right: 180px;
  }

  .bgContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Move the background behind other content */
  }
  .bgimage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .bgimage img {
    max-width: 100%;
  }

  .FormBox {
    transform: scale(0.8);
  }

  .bgimage2 {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translate(0, 110px) scale(1.1);
  }

  .bgimage2 img {
    max-width: 100%;
  }
}

/* ----------Medium Devices------------ */
@media (max-width: 768px) {
  .secondbox {
    padding-right: 100px;
  }
  .bgimage2 {
    transform: translate(15px, 175px) scale(1.2);
  }
}

/* ----------Small Devices------------ */
@media (max-width: 576px) {
  .secondbox {
    width: 80%;
    transform: scale(1);
    padding-right: none;
  }
  .bgimage2 {
    transform: translate(100px, 205px) scale(3);
  }
}

/* ----------Small Devices Responsive------------ */
@media (max-width: 430px) {
  .firstbox {
    display: none;
  }
  .secondbox {
    transform: scale(0.9);
    padding-right: 0px;
  }
}


/* ----------Small Devices Responsive------------ */
@media (max-width: 414px) {

   .form_container {
    top: -120px;
  }
 
  .FormBox {
    height: 1000px;
  }

  .button__container {
    gap: 140px;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  transform: scale(1.5);
  }
  .button , .button2 {
    box-shadow: 0px 0px 3px rgba(251, 249, 249, 0.15), inset 2px 2px 5px rgba(255, 255, 255, 0.2), 0 0 8px rgba(252, 252, 252, 0.48);
  }
}


/* ----------Small Devices Responsive------------ */
@media (max-width: 410px) {
.FormBox {
  transform: scale(0.7);
  
}
.form_container {
  top: -165px;
}

.button__container {
  gap:  100px;
padding-top: 20px;
justify-content: center;
align-items: center;
transform: scale(1.4);
}

.button , .button2 {
  width: 120px;
  height: 50px;
}
}