@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,100;1,100&family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,200;1,200&family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,300;1,300&family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,400;1,400&family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,500;1,500&family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,600;1,600&family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,700;1,700&family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,800;1,800&family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Montserrat:ital,wght@0,900;1,900&family=Oswald:wght@700&display=swap");

/* * {  
  border : 1px solid red;
} */
.App {
  text-align: center;
}
.force-light-mode {
  background-color: white !important;
  color: black !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
