.Collaborations__carousel__item .carousel-indicators button {
    width : 20px;
    height : 20px;
    border-radius : 50%;
    background-color : rgb(195, 194, 194);
}
.Collaborations__carousel__item .carousel-indicators {
    gap : 10px;
}
.Collaborations__carousel__item .carousel-indicators button.active {
    background-color : #438bc5;
}