.Partners__container {
  padding: 50px 0;
  /* margin : 10px; */
  background: radial-gradient(#f7f7f7, #bfcbd7);
}

/* ----------- Title Container ------------ */

.Title__container {
  padding: 0 3%;
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.Title {
  color: #ae4646;
  font-size: 80px;
  text-align: left;
  font-weight: 900;
  font-family: "Montserrat";
}

/* ------------ Title Texts  ---------------*/

/* .text__light {
    font-weight: 200;
    font-size: 50px;
} */

/* .text__bold {
    font-weight: 900;
    font-size: 50px;
} */

/* ----------- Title Description ---------- */

.TitleDescription__container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.TitleDescription__capsule {
  padding: 5px 0;
  padding-left: 10px;
  width: 55%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-top-right-radius: 180px;
  border-bottom-right-radius: 180px;
  background: rgba(206, 115, 115, 0.44);
}

.TitleDescription {
  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 700;
  color: #982727;
  margin: 1rem 0;
  text-align: left;
  width: 100%;
  max-width: 900px;
}

/* ----------- custom back button --------- */
.custom__back__Button {
  border-radius: 50%;
  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.5);
  /* width: 5vw;
    height: 9vh; */
  width: 92px;
  height: 92px;
  z-index: 10;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  padding-right: 5px;
  font-size: 3.7rem;
  position: fixed;
  right: 2%;
  top: 70px;
  cursor: pointer;
  background: white;
}

.custom__back__Button img {
  transform: translateY(6px);
  object-fit: contain;
  max-width: 150%;
}

/* ----------------------------------Responsive---------------------------- */

@media (max-width: 1700px) {
  .custom__back__Button {
    width: 90px;
    height: 90px;
  }

  .TitleDescription {
    font-size: 1.7vw;
  }

  .Title {
    font-size: 4.7vw;
  }
}

@media (max-width: 1500px) {
  .custom__back__Button {
    width: 80px;
    height: 80px;
  }

  .custom__back__Button img {
    width: 100%;
    height: 100%;
    transform: translate(1px, 6px) scale(1.6);
    object-fit: contain;
  }
}

@media (max-width: 1400px) {
  .custom__back__Button {
    width: 65px;
    height: 65px;
  }
}

/* ----------Medium Devices------------ */
@media (max-width: 768px) {
  .custom__back__Button {
    width: 55px;
    height: 55px;
  }
}

/* ----------Medium Devices------------ */
@media (max-width: 576px) {
  .custom__back__Button {
    width: 45px;
    height: 45px;
  }
  .TitleDescription {
    font-size: 2vw;
  }
  .TitleDescription__capsule {
    width: 75%;
  }
}

/* ----------Small Devices------------ */
@media (max-width: 430px) {
  .custom__back__Button img {
    transform: translate(0.5px, 5.8px) scale(2.2);
  }
}



