.container {
    width : 100%;
    height : 100vh;
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    background : black;
}

.container h2 {
    text-align: left;
    font-family : "Montserrat";
    color : white;
    width : 60%;
    max-width : 500px;
    font-size : 3.1vw;
}

.topNavSection {
    position : absolute;
    top : 0;
    left : 0;
    width : 100%;
    display : flex;
    justify-content : flex-start;
    align-items : center;
    gap : 20px;
    padding : 10px 30px;
}

.logo {
    width : 4.2vw;
    height : 4.2vw;
}

.companyName {
    font-family : "Montserrat";
    font-weight : 400;
    color : white;
    font-size :  3.5vw;
}

@media (min-width : 2045px) {
    .companyName {
        font-size : 42px;
    }
    .logo {
        width : 70px;
        height : 70px;
    }
    .container h2 {
        font-size : 38px;
        width : 100%;
        max-width : 100%;
        text-align : center;
    }
}