.Navbarbox {
  background-color: transparent;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  transition: background-image 1s ease;
  flex-direction: column;
  display: flex;
}

.keeper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: row;
}

.Navbarbox:hover,
.Navbarbox.hovered {
  background-color: white;
  background-image: linear-gradient(to right, #ffffff, #5085b0);
}

.Navbarbox button {
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 20px;
  /* transform : color 0.7s ease; */
}

.Navbarbox:hover .buttonholder button,
.Navbarbox.hovered .buttonholder button {
  color: black;
}

.navbarContainer {
  background-color: transparent !important;
  position: sticky;
  top: 0;
  transition: transform 0.5s ease !important;
}

.logo {
  position: relative;
  background-image: url("/public/Navbar-logos/logobw1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  cursor: pointer;
  z-index: 1;
  /* width: 50px; */
  /* height: 50px; */
  width: inherit;
  height: inherit;
  /* transition : background-image 0.7s ease; */
}

.Navbarbox:hover .logo,
.Navbarbox.hovered .logo {
  background-image: url("/public/Navbar-logos/logo-real.png");
}

.logowrapper {
  height: 45px;
  width: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1px var(--padding-smi) 0px 0px;
  box-sizing: border-box;
  margin-right: 20px;
  border-bottom-right-radius: 12.5px;
  border-top-left-radius: 12.5px;
  overflow: hidden;
  box-shadow: 0 4px 8px 1px #112b41;
}

.buttonholder {
  display: flex;
  align-items: center;
  gap: 20px;
  align-content: space-between;
}

.sidepush {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: space-between;
  gap: 20px;
  height: 34px;
}

.buttonholder button,
.sidepush button {
  background-color: transparent;
  transition: color 0.3s ease;
}

.buttonholder button:hover,
.sidepush button:hover {
  color: black;
}

.navList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 30px;
  font-size: 20px;
  margin-right: 10px;
  align-content: space-between;
}

.navList li {
  margin: 0;
  padding: 0;
}

.linkd {
  text-decoration: none;
  color: white;
  width: inherit;
  display: inline-block;
  box-sizing: border-box;
}

.linkd:hover {
  color: #080909;
  font-weight: 500;
  /* font-size: 500; */
  text-shadow: 0.5px 0px 0px rgba(0, 0, 0, 0.8);
}

.sidepush,
.holdertext {
  font-family: "Montserrat" !important;
  align-items: center;
  font-weight: 400;
}

.buttonholder__menu button:active {
  color: black;
}

.navbar__menu {
  z-index: 10;
  display: none;
}

/* --------------------------------------- */

.menu__container {
  height: calc(10% + 25px);
  width: 100vw;
  position: absolute;
  /* Change from fixed to absolute */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  top: 100%;
  /* Position it at the bottom of the parent */
  margin: 0 auto;
}

.buttonholder__menu {
  display: flex;
  width: 92vw;
  height: 36px;
  overflow: hidden;
  margin: 0 auto;
  color: rgba(32, 236, 247, 1);
  background: rgba(7, 19, 23, 0.6);
  justify-content: space-around;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  transform: translateY(-100%);
  transition: transform 0.2s ease-out;
  /* -webkit-text-stroke-width: 1.3px;
  -webkit-text-stroke-color: rgba(32, 236, 247, 1); */
}

.menu__slider {
  transform: translateY(0);
}

.flex__props {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* --------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,600&display=swap");

/* Media Querries */

@media (max-width: 1180px) {
  .buttonholder__menu {
    width: 85vw;
  }

  .buttonholder {
    display: none;
  }

  .navbar__menu {
    display: inline-block;
  }

  .holdertext,
  .linkd {
    font-size: 15px;
  }

  .logowrapper {
    width: 35px;
    height: 35px;
    border-bottom-right-radius: 10.5px;
    border-top-left-radius: 10px;
    box-shadow: 0 4px 8px #112b41;
  }

  /* ----------------------------- */

  .Navbarbox {
    background: linear-gradient(to right, #ffffff, #5085b0);
    z-index: 10;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 96vw;
    margin: 0 auto;
    min-height: 20px !important;
  }

  .logo {
    background-image: url("/public/Navbar-logos/logo-real.png");
  }

  .navbarContainer {
    box-shadow: none !important;
  }

  /* ----------------------------- */
}

.navbarslide {
  display: none;
}

.navbarslide.visible {
  display: block;
}

@media (min-width: 1180px) {
  /* ----------------------------------- */
  .menu__container {
    display: none;
  }

  /* ----------------------------------- */
}

/* ----------Small Devices------------ */
@media (max-width: 576px) {
  .navList {
    display: none;
  }
  .buttonholder__menu {
    display: none;
  }
  .Navbarbox {
    width: 90vw;
    overflow: hidden;
  }

  /* ----------------Navbar Slide and its inner components---------------- */
  .navbarslide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 10px;
    padding: 30px;
  }

  .navbarslide div:active {
    transform: scale(0.92); /* Adjust the scale value as needed */
    transition: transform 0.2s ease-in-out;
    background-color: #438bc5;
    text-shadow: 0px 3px 3px #ffffff90;
  }

  .home,
  .blog,
  .product,
  .usecase,
  .contactus {
    width: 100%;
    border: 2px solid #145284;
    border-radius: 50px;
    height: 3.5vh;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    font-family: "Montserrat";
    font-weight: 500;
    text-shadow: 0px 3px 3px #000000a1;
    padding-left: 2vw;
    font-size: 3.5vw;
    user-select: none;
  }
  /* ---------------------------------------- */
  .activeSmallScreen {
    height: 300px;
    padding-top: 8px;
    align-items: flex-start !important;
    transition: height 0.5s ease;
    box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.6);
  }
  .linked {
    text-decoration: none;
    color: white;
    font-size: inherit;
  }
}

/* ----------Small Devices Responsive------------ */
@media (max-width: 430px) {
  .navbarslide {
    padding: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .home,
  .blog,
  .product,
  .usecase,
  .contactus {
    padding-left: 3vw;
    font-size: 4.5vw;
    height: 4.5vh;
  }
  .activeSmallScreen {
    height: 330px;
  }
}

/* ----------Small Devices Responsive------------ */
@media (max-width: 414px) {
  .navbarslide {
    padding: 22px;
    padding-left: 10px;
    padding-right: 20px;
  }
  .home,
  .blog,
  .product,
  .usecase,
  .contactus {
    padding-left: 4vw;
    height: 5vh;
    padding: 22px;
  }
  .activeSmallScreen {
    height: 365px;
  }
}

/* ----------Small Devices Responsive------------ */
@media (max-width: 360px) {
  .Navbarbox {
    width: 80vw;
  }
}
